import React from 'react'
import './estilo.rodape.scss'


export default function Rodape(){
    return(
        <section>
            <div className='conteinerGeralRodape'>
            <p>Empório do Gaúcho</p>
            <p>2023© - Todos os direitos reservados® </p>
            </div>
        </section>
    )
}