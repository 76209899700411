import { BrowserRouter, Routes, Route } from 'react-router-dom'

import FaixaCabecalho from '../Components/FaixaCabecalho'
import CabecalhoN from '../Components/CabecalhoN'
import Rodape from '../Components/Rodape'
import RodapeN from '../Components/RodapeN'

import Inicio from '../Inicio'
import SobreNos from '../SobreNos'
import Produtos from '../Produtos'
import LojaVirtual from '../LojaVirtual'

export default function NaoAutenticados() {
    return (
        <BrowserRouter>
            <FaixaCabecalho />
            <CabecalhoN />
            <Routes>
                <Route path='/' element={<Inicio />} />
                <Route path='/SobreNos' element={<SobreNos />} />
                <Route path='/Produtos' element={<Produtos />} />
                <Route path='/LojaVirtual' element={<LojaVirtual />} />
                
                <Route path='/static' element={<Inicio />} />
                <Route path='*' element={<Inicio />} />
            </Routes>
            <RodapeN />
            <Rodape />
        </BrowserRouter>
    )
}