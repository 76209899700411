import React from 'react'
import './estilo.rodapeN.scss'

export default function RodapeN() {
    return (
        <section>
            <div className='conteinerGeralRodapeN'>
               
            </div>
        </section>
    )
}