import React from 'react'
import Manutencao from '../assets/manut.jpg'

import './lojavirtual.estilo.scss'

export default function LojaVirtual() {
    return (
        <div className='conteinerGeralLojaVistual'>
            <h1>Loja Virtual</h1>
            <img src={Manutencao} alt='' />
        </div>
    )
}